import { navigate, RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAppDispatch } from '../..';
import illPhoneNotificationLogo from '../../assets/ill-phone-notification.svg';
import bankDirectLogo from '../../assets/logo-smartpay-bank-direct.svg';
import Button from '../../components/Form/Button';
import Header from '../../components/Header/Header';
import MainLayout from '../../components/Layout';
import MerchantHeader from '../../components/MerchantHeader/MerchantHeader';
import useAppSelector from '../../hooks/use-app-selector';
import { getCheckoutSession } from '../../redux/good';
import styles from './BankDirectScreen.module.scss';

const LOADING_SIZE = 110;
const LOADING_SRTROKE = 8;
const R = (LOADING_SIZE - LOADING_SRTROKE) / 2;
const CX = LOADING_SIZE / 2;
const CY = CX;
const L = 2 * Math.PI * R;

const pad = (s: number | string) => {
  return s.toString().length < 2 ? `0${s}` : s;
};

const formatCountDown = (secs: number) => {
  const mins = secs / 60;

  return `${Math.floor(mins)}:${pad(secs % 60)}`;
};

const BankDirectScreen: FC<RouteComponentProps> = () => {
  useTrackPageView();

  const dispatch = useAppDispatch();
  const isTokenFlow = useAppSelector((state) => state.misc.isTokenFlow);
  const anonymousId = useAppSelector((state) => state.auth.anonymousId);
  const sessionIdWithSignature = useAppSelector(
    (state) => state.misc.sessionIdWithSignature
  );
  const { isBankDirectFeatureEnabled } = useAppSelector(
    (state) => state.featureFlag
  );
  const countDownRef = useRef(300);
  const [countDown, setCountDown] = useState(countDownRef.current);
  const [isTimeout, setIsTimeout] = useState(false);
  const socketRef = useRef<Socket>();

  const verifyOrderStatus = useCallback(async () => {
    const sessionResultAction = await dispatch(
      getCheckoutSession({ sessionIdWithSignature })
    );

    if (getCheckoutSession.fulfilled.match(sessionResultAction)) {
      const { status } =
        sessionResultAction.payload.checkoutSession.order || {};

      return status;
    }

    return 'unknown';
  }, [dispatch, sessionIdWithSignature]);

  const onConnect = useCallback(async () => {
    const status = await verifyOrderStatus();

    if (status === 'succeeded' || status === 'requires_capture') {
      navigate('/payment-success');

      return;
    }

    socketRef.current?.emit('authorize', {
      anonymousId,
      checkoutSessionId: sessionIdWithSignature,
    });
  }, [verifyOrderStatus, anonymousId, sessionIdWithSignature]);

  const onAuthorizeResult = useCallback(
    async (event) => {
      if (event.eventData.checkoutSessionId !== sessionIdWithSignature) {
        return;
      }

      const sessionResultAction = await dispatch(
        getCheckoutSession({ sessionIdWithSignature })
      );

      if (getCheckoutSession.rejected.match(sessionResultAction)) {
        navigate('/error/session-invalid');
      } else {
        const { status } =
          sessionResultAction.payload.checkoutSession.order || {};

        if (status === 'succeeded' || status === 'requires_capture') {
          navigate('/payment-success');
        } else if (status === 'requires_authorization') {
          // soft decline
        } else {
          switch (event.publicRejectionCode) {
            case 'past_due_charges': {
              navigate('/error/payment-overdue-installments');

              break;
            }
            case 'insufficient_balance': {
              navigate('/error/payment-over-limit');

              break;
            }
            default: {
              navigate('/error/payment-rejected');

              break;
            }
          }
        }
      }
    },
    [dispatch, sessionIdWithSignature]
  );

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    const start = new Date().getTime();
    const tick = () => {
      const current = 300 - Math.floor((new Date().getTime() - start) / 1000);

      countDownRef.current = current > 0 ? current : 0;

      setCountDown(countDownRef.current);

      if (countDownRef.current > 0) {
        timer = setTimeout(tick, 250);
      } else {
        setIsTimeout(true);
      }
    };

    tick();

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  useEffect(() => {
    if (isBankDirectFeatureEnabled) {
      socketRef.current = io(
        `https://connect.${process.env.REACT_APP_BASE_DOMAIN}/bank-direct`,
        {
          withCredentials: true,
          transports: ['websocket'],
          reconnection: false,
        }
      );

      socketRef.current?.on('connect', onConnect);
      socketRef.current?.on('authorize-result', onAuthorizeResult);
    } else {
      navigate('/payment');
    }

    return () => {
      if (isBankDirectFeatureEnabled) {
        socketRef.current?.off('connect', onConnect);
        socketRef.current?.off('authorize-result', onAuthorizeResult);
        socketRef.current?.close();
      }
    };
  }, [isBankDirectFeatureEnabled, onConnect, onAuthorizeResult]);

  return (
    <>
      <div>
        <div className={cx('rwd-wrapper', isTokenFlow ? 'token-flow' : '')}>
          <aside>
            <Header />
            <MerchantHeader />
          </aside>
          <MainLayout showLogo={false}>
            <div className={styles.container}>
              <div className={styles['logo-container']}>
                <img
                  src={bankDirectLogo}
                  alt="Smartpay Bank Direct"
                  className={styles.logo}
                  width="310"
                />
              </div>
              <svg
                width="110"
                height="110"
                viewBox="0 0 110 110"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx={CX}
                  cy={CY}
                  r={R}
                  strokeWidth={LOADING_SRTROKE}
                  stroke="#e3e7f2"
                />
                <circle
                  cx={CX}
                  cy={CY}
                  r={R}
                  strokeWidth={LOADING_SRTROKE}
                  strokeDasharray={`0 ${L} ${L} ${L}`}
                  strokeDashoffset={L}
                  strokeLinecap="round"
                  stroke="#ff3f8f"
                  className={cx(styles.waiting, isTimeout && styles.timeout)}
                />
                <text
                  x={CX}
                  y={CY + 3}
                  fill="#231c45"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  className={styles['waiting-timer']}
                >
                  {formatCountDown(countDown)}
                </text>
              </svg>

              <div
                className={cx(styles['info-box'], isTimeout && styles.timeout)}
              >
                {isTimeout ? (
                  <div className={styles['info-container']}>
                    <h2 className={styles.center}>
                      Smartpayアプリで、
                      <br />
                      銀行口座からの支払いを確認してください。
                    </h2>
                    <Button label="戻る" onClick={() => navigate(-1)} />
                  </div>
                ) : (
                  <>
                    <img src={illPhoneNotificationLogo} alt="" width="88" />
                    <div className={styles['info-container']}>
                      <h2>
                        Smartpayアプリから、
                        <br />
                        銀行口座からの支払いを確認してください。
                      </h2>
                      <p>
                        プッシュ通知が届かない場合は、一度アプリを閉じてから再度開いてください。
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <p className={styles.note}>
              {/* eslint-disable-next-line max-len */}
              注意：集中モードやおやすみモードなどでプッシュ通知の設定がオフになっている場合には、手動で通知を確認していただく必要があります。Smartpayからの通知の許可を常にオンにしてください。
            </p>
          </MainLayout>
        </div>
      </div>
    </>
  );
};

export default BankDirectScreen;
